import React from 'react'

const Hero = () => {
  return (
    <div className='hero-container'>
        
      <img src='https://venezuela.qsocialnow.com/images/1.png' className='hero-img'></img>
    </div>
  )
}

export default Hero

