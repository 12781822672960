import './App.css';
import Hero from '../src/Components/Hero'
import Middle from './Components/Middle';

function App() {
  return (
   <>
   <Hero/>
   <Middle/>
   </>
  );
}

export default App;
