import React from 'react';
import '../App.css'; // Asegúrate de crear este archivo CSS y enlazarlo aquí

const Middle = () => {
  return (
    <>
      <div className='section-container'>
        <img src='https://venezuela.qsocialnow.com/images/2.png' className='middle-img' alt='Imagen 2'></img>
      </div>
      <div className='section-container'> 
        <img src='https://venezuela.qsocialnow.com/images/3.png' className='section-imgs' alt='Imagen 3'></img>
      </div>
      <div className='section-container'>
        <img src='https://venezuela.qsocialnow.com/images/4.png' className='section-imgs' alt='Imagen 4'></img>
      </div>
      <div className='section-container'>
        <img src='https://venezuela.qsocialnow.com/images/5.png' className='section-imgs' alt='Imagen 5'></img>
      </div>
      <div className='section-container'>
        <img src='https://venezuela.qsocialnow.com/images/6.png' className='section-imgs' alt='Imagen 6'></img>
      </div>
      <div className='section-container'>
        <img src='https://venezuela.qsocialnow.com/images/7.png' className='section-imgs' alt='Imagen 7'></img>
      </div>
      <div className='section-container'>
        <img src='https://venezuela.qsocialnow.com/images/8.png' className='section-imgs' alt='Imagen 8'></img>
      </div>
      <div className='section-container'>
        <img src='https://venezuela.qsocialnow.com/images/9.png' className='section-imgs' alt='Imagen 9'></img>
      </div>
    </>
  );
}

export default Middle;
